<template>
  <div class="table-box">
        <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
  </div>
</template>
<script>
import Vue from "vue";
export default {
  data() {
    let _this = this;
    return {
        opt: {
        columns: [
          { label: "注册时间", key: "createTime"},
          { 
            label: "人脸照片", 
            type:"img",
            key: "attachment",
            on: row => {
              _this.$alert(`<img src="${row.attachment}">`, "查看人脸照片", {
                dangerouslyUseHTMLString: true,
                callback: action => {}
              });
            }
             },
        ]
      },
    };
  },
  created() {
  },
  activated() {
  },
  mounted(){
    console.log('mounted')
     
  },
  methods: {
    onGet(opt) {
      let dto = {
        current: opt.skip,
        pageSize: opt.limit,
        deviceId: this.$route.params.id,
        keyType: 6,
      };
      this.post("homelock-service/user-device-key/web/api/v1/record", dto, {
        isUseResponse: true
      }).then(res => {
        if(res.data.code == 0) {
          let addressPrefix = '';
          if(Vue.config.BASE_URL == 'https://apartment-preapi.wfyundata.com' || Vue.config.BASE_URL == 'https://apartment-api.wavewisdom.com') {
            addressPrefix = 'https://img.wavewisdom.com/';
          }else {
            addressPrefix = 'http://test-image.wavewisdom.com/';
          }
          if(res.data.data && res.data.data.length > 0) {
            res.data.data.forEach(item => {
              item.createTime = item.createTime || '--';
              item.attachment = item.attachment ? addressPrefix + item.attachment : '--';
            });
          }
          opt.cb(res.data);
        } 
      });
    },
  }
};
</script>
<style lang="scss">
.table-box{
  height: 620px;
}
</style>