<template>
  <div class="personalDeviceDetail">
    <div class="title">
      <app-header :title="title" :isShowBack="true"></app-header>
    </div>
    <ul class="tab-ul">
      <li :class="{ ative: currentTab == 'personalDeviceInfo' }" @click="toggleTab('personalDeviceInfo')">
        设备信息
      </li>
      <li :class="{ ative: currentTab == 'personalFaceInfo' }" @click="toggleTab('personalFaceInfo')">
        人脸信息
      </li>
    </ul>
    <keep-alive>
      <personalDeviceInfo :is="currentTab"></personalDeviceInfo>
    </keep-alive>
  </div>
</template>
<script>
import personalDeviceInfo from "./personalDeviceInfo";
import personalFaceInfo from "./personalFaceInfo";
export default {
  components: {
    personalDeviceInfo,
    personalFaceInfo,
  },
  data() {
    let _this = this;
    return {
      title: "分离式门禁详情",
      currentTab: "personalDeviceInfo",
    };
  },
  activated() {
    console.log("personalDeviceDetail activated!!");
    this.currentTab = "personalDeviceInfo";
  },
  methods: {
    toggleTab: function (tab) {
      this.currentTab = tab; // tab 为当前触发标签页的组件名
    },
  },
};
</script>
<style lang="scss">
.personalDeviceDetail {
  .danger {
    color: #f56c6c;
  }
  .titleSpans {
    padding-bottom: 20px;
  }
  .titleSpan {
    color: #606266;
  }
  .info1 {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #606266;
    .txt {
      margin-right: 20px;
    }
  }
  .info {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #606266;
    background-color: #e4e7ed;

    .txt {
      vertical-align: top;
      display: inline-block;
      margin-right: 20px;
    }
    .txt2 {
      display: inline-block;
      width: 165px;
    }
    .txt3 {
      display: inline-block;
    }
  }
  .table-row-img {
    width: 75px;
  }
  // tab样式
  .tab-ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    height: 42px;
    box-sizing: border-box;
    margin-bottom: 40px;
    width: 100%;
    border-bottom: 2px solid #e4e7ed;

    li {
      cursor: pointer;
      padding-left: 18px;
      padding-right: 18px;
      height: 42px;
      line-height: 42px;
      float: left;
      text-align: center;
      margin-right: 13px;
      font-size: 15px;
      background-color: #ffffff;
      color: #303133;
      box-sizing: border-box;
      border-bottom: 2px solid #e4e7ed;

      &.ative {
        // color: #ffffff;
        // background: #5e74f1;
        color: #30bac1;
        border-bottom: 2px solid #30bac1;
      }
    }

    &::after {
      content: "";
      display: block;
      clear: both;
    }
  }
  .landlord-manage-box .el-tab-pane {
    height: 650px;
  }
}
</style>
<style lang="scss" scope>
.el-message-box {
  min-width: 200px !important;
}
</style>
