<template>
  <div>
    <div class="personalDeviceInfo">
      <div class="personalDeviceInfo-lt">
        <div>门禁详情</div>
        <div class="content-box" v-for="item in dataListLeft1" :key="item.key">
          <div class="title">{{ item.label }}</div>
          <span class="content">{{ form[item.key] }}{{ item.value }}</span>
        </div>
      </div>
      <div class="personalDeviceInfo-rt">
        <div class="content-box" v-for="item in dataListRight1" :key="item.key">
          <div class="title">{{ item.label }}</div>
          <span class="content">{{ form[item.key] }}{{ item.value }}</span>
        </div>
      </div>
    </div>
    <div class="personalDeviceInfo">
      <div class="personalDeviceInfo-lt">
        <div class="personalDeviceInfo-title">关联设备信息</div>
        <div class="content-box" v-for="item in dataListLeft2" :key="item.key">
          <div class="title">{{ item.label }}</div>
          <span class="content">{{ associationForm[item.key] }}{{ item.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      dataListLeft1: [
        {
          label: "门禁型号：",
          key: "model",
          isCol50: true,
        },
        {
          label: "门禁MAC：",
          key: "deviceMac",
          isCol50: true,
        },
        {
          label: "管理员：",
          key: "userName",
          isCol50: true,
        },
        {
          label: "管理员手机号码：",
          key: "userPhone",
          isCol50: true,
        },
      ],
      dataListRight1: [
        {
          label: "门禁软件版本：",
          key: "firmwareVersion",
          isCol50: true,
        },
        {
          label: "添加时间：",
          key: "createTime",
          isCol50: true,
        },
      ],
      dataListLeft2: [
        {
          label: "关联设备型号：",
          key: "model",
          isCol50: true,
        },
        {
          label: "关联设备MAC：",
          key: "deviceMac",
          isCol50: true,
        },
      ],
      form: {},
      associationForm: {},
    };
  },
  created() {
    console.log("personalDeviceInfo created!!");
  },
  activated() {
    console.log("personalDeviceInfo activated!!");
    let id = this.$route.params.id;
    this.get("homelock-service/api/v1/device/" + id,{},{
        isUseResponse: true,
      }).then((res) => {
      if (res.data.code == 0) {
        let item = res.data.data ? res.data.data : {};
        item.model = item.model || "--";
        item.deviceMac = item.deviceMac || "--";
        item.userName = item.userName || "--";
        item.userPhone = item.userPhone || "--";
        item.firmwareVersion = item.firmwareVersion || "--";
        item.createTime = item.createTime || "--";
        this.$set(this, "form", item);

        let relationDevicesItem = res.data.data && res.data.data.relationDevices && res.data.data.relationDevices.length > 0 ? res.data.data.relationDevices[0] : {};
        relationDevicesItem.model = relationDevicesItem.model || "--";
        relationDevicesItem.deviceMac = relationDevicesItem.deviceMac || "--";
        this.$set(this, "associationForm", relationDevicesItem);
      }
    });
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.table-box {
  height: 620px;
}

.personalDeviceInfo {
  margin: auto;
  width: 1200px;
  display: flex;
  flex-wrap: wrap;

  .personalDeviceInfo-title {
    margin-top: 40px;
  }

  .personalDeviceInfo-lt {
    width: 50%;
  }

  .personalDeviceInfo-rt {
    width: 50%;
  }

  .content-box {
    margin: 20px 0;
    font-size: 15px;

    .title {
      display: inline-block;
      padding-right: 40px;
      text-align: right;
      width: 200px;
      color: #303133;
    }

    .content {
      color: #666;
    }
  }

  .editBox {
    display: inline-block;
    margin: auto;

    .editTitle {
      display: inline-block;
      padding-right: 10px;
      text-align: right;
      width: 101px;
      color: #606266;
    }

    .editContent {
      margin: 10px;
    }

    .el-textarea {
      width: 53%;
    }

    .el-input {
      width: 53%;
    }
  }

  .personalDeviceInfo-check-title {
    padding-bottom: 10px;
    font-weight: 700;
  }

  .personalDeviceInfo-check-text {
    padding-bottom: 10px;
    display: flex;

    span {
      padding-right: 46px;
    }
  }

  .table-box {
    height: 400px;
    overflow-y: auto;
  }
}
</style>